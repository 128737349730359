
.userlist {
  padding: 2rem;
  margin: 2rem;
  display: flex;
  flex-direction: column;
}
.btn-expire {
  background-color: red;
  color: red;
}

.userlisttext {
  position: relative;
  top: -47px;
  background: white;
  padding: 7px;
  width: 106px;
  color: blue;
}

.user {
  border: thin solid green;
  padding: 2rem;
  margin: 2rem;
  display: flex;
  flex-direction: column;
}
 
.usertext {
  position: relative;
  top: -47px;
  background: white;
  padding: 7px;
  width: 106px;
  color: green;
}
img {
  max-height:10%;
  max-width:10%;
}

.custom-btn {
  background: #fff;
  color: red;
  border: 2px solid red;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}

